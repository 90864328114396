<div class="main-container margin-auto" fxLayoutAlign="center stretch">
  <ng-container *ngIf="smallView; else largerView">
    <div class="sidenav-container">
      <div class="sidenav">
        <mat-expansion-panel class="expansion-panel mat-elevation-z0" [(expanded)]="expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="title margin-0 font-bold">Contents</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let section of sections">
            <div class="margin-top-2">
              <ng-container *ngIf="section?.subsections; else defaultView">
                <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      class="margin-0 section sparro-grey-800"
                      (click)="toTop(section.id)">
                      {{ section.text }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let subsection of section.subsections">
                    <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                      {{ subsection.text }}
                    </p>
                  </ng-container>
                </mat-expansion-panel>
              </ng-container>
              <ng-template #defaultView>
                <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                  {{ section.text }}
                </p>
              </ng-template>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </div>
    </div>
  </ng-container>

  <ng-template #largerView>
    <div class="sidenav-container relative">
      <div class="sidenav">
        <p class="title margin-0 font-bold section-border">Contents</p>
        <ng-container *ngFor="let section of sections">
          <div class="section-border margin-top-2">
            <ng-container *ngIf="section?.subsections; else defaultView">
              <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title
                    class="margin-0 section sparro-grey-800"
                    (click)="toTop(section.id)">
                    {{ section.text }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let subsection of section.subsections">
                  <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                    {{ subsection.text }}
                  </p>
                </ng-container>
              </mat-expansion-panel>
            </ng-container>
            <ng-template #defaultView>
              <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                {{ section.text }}
              </p>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <div fxLayout="column" class="margin-9" fxLayoutGap="24px">
    <div class="padding-bottom-3 breadcrumb-section">
      <p class="margin-0 desc">
        <a href="https://uni.xyz/policy" class="no-decor-hover">Platform Policies</a> / Business /
        <span class="font-bold">Payment Liability</span>
      </p>
    </div>
    <div class="detail-section margin-top-9">
      <div fxLayout="column" fxLayoutGap="80px">
        <div fxLayout="column" fxLayoutGap="40px">
          <h1 class="margin-0">Payment and payment liability</h1>
          <p class="margin-0 desc">Effective from August 20, 2022</p>
        </div>
        <div fxLayout="column" fxLayoutGap="48px" id="1">
          <p class="margin-0 title font-bold">1. Invoices</p>
          <p class="margin-0 desc">
            The initial invoice will be sent by UNI (“UNI,” “us,” or “we”) upon completion of a
            sponsorship purchase, or within 30 days of completion of an Order, whichever is earlier.
            Invoices will be sent to your billing address as set forth on the Order. All invoices
            (other than corrections of previously provided invoices) pursuant to the IO will be sent
            within 30 days of completion of a campaign set forth in an Order. Upon your request, UNI
            will provide proof of performance for the invoiced period.
          </p>
        </div>
        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="2">2. Payment date</p>
          <p class="margin-0 desc">
            You agree to make full payment (in US Dollars) no later than 30 days of your receipt of
            UNI’s invoice, or as otherwise stated in a payment schedule set forth on an applicable
            Order. UNI may at its discretion assess a late charge of 1.5 percent per each 30-day
            period that payment is overdue, or where the law does not permit such fee, the maximum
            such fee permitted by law.
          </p>
        </div>
        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="3">3. Payment liability</p>
          <p class="margin-0 desc">
            The entity agreeing to the Sponsorship Terms of Service is the party liable for payment,
            regardless of whether the Order was placed on behalf of another principal or third party
            (such as by an agency). We reserve the right to require payment in advance, at our
            discretion.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
