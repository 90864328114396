import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BusinessRoutingModule } from './business-routing.module';
import { PaymentLiabilityComponent } from './payment-liability/payment-liability.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonsModule } from '@sparro/app/shared/components/buttons/buttons.module';
import { CardsModule } from '@sparro/app/shared/components/cards/cards.module';
import { ImagesModule } from '@sparro/app/shared/components/images/images.module';
import { LayoutModule } from '@sparro/app/shared/components/layout/layout.module';
import { PipesModule } from '@sparro/app/shared/pipes/pipes.module';
import { LoaderModule } from '@sparro/app/shared/components/loader/loader.module';
import { SharedModule } from '@about/app/shared/shared.module';
import { MaterialModule } from '@sparro/app/material.module';
import { SponsorshipPoliciesComponent } from './sponsorship-policies/sponsorship-policies.component';
import { SponsorshipTermsComponent } from './sponsorship-terms/sponsorship-terms.component';

@NgModule({
  declarations: [
    PaymentLiabilityComponent,
    SponsorshipPoliciesComponent,
    SponsorshipTermsComponent
  ],
  imports: [
    CommonModule,
    BusinessRoutingModule,
    FlexLayoutModule,
    SharedModule,
    ImagesModule,
    LayoutModule,
    ButtonsModule,
    CardsModule,
    LoaderModule,
    MaterialModule,
    PipesModule
  ]
})
export class BusinessModule {}
