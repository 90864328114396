<div class="main-container margin-auto" fxLayoutAlign="center stretch">
  <ng-container *ngIf="smallView; else largerView">
    <div class="sidenav-container">
      <div class="sidenav">
        <mat-expansion-panel class="expansion-panel mat-elevation-z0" [(expanded)]="expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="title margin-0 font-bold">Contents</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let section of sections">
            <div class="margin-top-2">
              <ng-container *ngIf="section?.subsections; else defaultView">
                <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      class="margin-0 section sparro-grey-800"
                      (click)="toTop(section.id)">
                      {{ section.text }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let subsection of section.subsections">
                    <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                      {{ subsection.text }}
                    </p>
                  </ng-container>
                </mat-expansion-panel>
              </ng-container>
              <ng-template #defaultView>
                <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                  {{ section.text }}
                </p>
              </ng-template>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </div>
    </div>
  </ng-container>

  <ng-template #largerView>
    <div class="sidenav-container relative">
      <div class="sidenav">
        <p class="title margin-0 font-bold section-border">Contents</p>
        <ng-container *ngFor="let section of sections">
          <div class="section-border margin-top-2">
            <ng-container *ngIf="section?.subsections; else defaultView">
              <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title
                    class="margin-0 section sparro-grey-800"
                    (click)="toTop(section.id)">
                    {{ section.text }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let subsection of section.subsections">
                  <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                    {{ subsection.text }}
                  </p>
                </ng-container>
              </mat-expansion-panel>
            </ng-container>
            <ng-template #defaultView>
              <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                {{ section.text }}
              </p>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <div fxLayout="column" class="margin-9" fxLayoutGap="24px">
    <div class="padding-bottom-3 breadcrumb-section">
      <p class="margin-0 desc">
        <a href="https://uni.xyz/policy" class="no-decor-hover">Platform Policies</a> / Business /
        <span class="font-bold">Sponsorship policies</span>
      </p>
    </div>
    <div class="detail-section margin-top-9">
      <div fxLayout="column" fxLayoutGap="80px">
        <div fxLayout="column" fxLayoutGap="40px">
          <h1 class="margin-0">Sponsorship policies</h1>
          <p class="margin-0 desc">Effective from August 20, 2022</p>
        </div>
        <p class="margin-0 desc">
          We believe sponsoring competitions on UNI should result in better experiences for people
          using the platform and positive results for our sponsor partners. We want your brand to
          reach the right audience and have your products or services be helpful to people. As a
          result, we have certain standards for how you showcase your brand with the competition and
          may reject some branded contents that do not comply with these standards. We have the
          right, but not the responsibility, to review your content for compliance with our
          standards. However, in all cases, you bear responsibility for reviewing and ensuring all
          sponsored content complies with our Sponsoring Policies. Accordingly, we ask you to
          familiarize yourself with these Sponsoring Policies carefully.<br /><br />These Sponsoring
          Policies govern all sponsored content and brands' logos that run on the UNI platform.
          These sponsoring policies expressly are incorporated into the Sponsoring Terms. All
          capitalized terms in these Sponsoring Policies will have the same meaning as in the
          Sponsoring Terms.
        </p>
        <div fxLayout="column" fxLayoutGap="48px" id="1">
          <p class="margin-0 title font-bold">1. Business name policies</p>
          <div fxLayout="column" fxLayoutGap="32px">
            <p class="margin-0 desc">
              Your "Sponsored by" business name should reflect the entity you are sponsoring for. It
              should not include qualifiers, taglines, or descriptions outside the business name
              itself. You may reference the specific product or brand name your sponsorship is
              about, and you may include a reference to the corporate structure of your business.
            </p>
            <ul class="margin-0 desc">
              <li>Not permitted: UNI, World’s largest competition organizer</li>
              <li>Acceptable: UNI</li>
              <li>Acceptable: uni.xyz</li>
              <li>Acceptable: competitions.UNI</li>
            </ul>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="2">2. Sponsorship copy policies</p>
          <div fxLayout="column" fxLayoutGap="32px">
            <p class="margin-0 desc">
              UNI’s sponsorship copy consists of the name of the business (in bold) and tag line
              which will be visible all across UNI:
            </p>
            <ul class="margin-0 desc">
              <li>
                Business Name: Maximum of 50 characters. A business name should be your correct
                business name so that people can identify your business quickly.
              </li>
              <li>
                Tag line: Maximum of 120 characters. Provide more detail on the product or service
                or cause you want to promote by sponsoring a competition. We may provide you with
                options to input multiple taglines and callout links.
              </li>
            </ul>
            <p class="margin-0 desc">
              All promotional content/material must adhere to our
              <a href="https://uni.xyz/policy" class="no-decor font-bolder"
                >UNI content policies and guidelines</a
              >. Your sponsored content should be clear, well-formatted, and free from grammatical,
              capitalization, and punctuation errors. Overall, the general rules for your sponsored
              content are:
            </p>
            <ul class="margin-0 desc">
              <li>Do write a copy of your sponsored content in English only.</li>
              <li>
                Do make sure your sponsored content is free from grammatical, capitalization, and
                punctuation errors.
              </li>
              <li>Do not include links in your sponsored content.</li>
              <li>Do not use profanity.</li>
              <li>Do not include sexual-related content in your copy.</li>
              <li>
                Do not use provocative or shocking language, or create misleading expectations in
                your sponsored content copy in order to attract users to click on your website.
              </li>
              <li>
                Do not mention UNI in your sponsored content copy without prior written permission
                and approval from UNI.
              </li>
            </ul>
            <p class="margin-0 desc">
              Correct spelling, punctuation, and grammar make your sponsored content better and
              easier to read. Here are some guidelines you should follow when crafting your copy:
            </p>
            <p class="margin-0 desc font-bolder">Grammer</p>
            <ul class="margin-0 desc">
              <li>
                The Sponsored content text must use proper grammar and spelling. American and
                British English are both acceptable.
              </li>
              <li>
                Not permitted: Try our editing services, so you don’t make silly spleling mitskaes.
              </li>
              <li>
                Acceptable: Try our editing services, so you don’t make silly spelling mistakes.
              </li>
              <li>Sentence fragments are allowed.</li>
            </ul>
            <p class="margin-0 desc font-bolder">Spacing</p>
            <ul class="margin-0 desc">
              <li>Include a single space between sentences.</li>
              <li>Acceptable: Get 20% off all products at our workshop. Join our session today.</li>
              <li>There should not be spaces before punctuation marks like periods and commas.</li>
              <li>
                Not permitted: Find out which 20 companies will be raising money this year. Download
                our e-book today .
              </li>
              <li>
                Acceptable: Find out which 20 companies will be raising money this year. Download
                our e-book today.
              </li>
            </ul>

            <p class="margin-0 desc font-bolder">Capitalization</p>
            <ul class="margin-0 desc">
              <li>
                Correct capitalization must be used at all times. Use sentence cases in both the
                headline and body.
              </li>
              <li>Not permitted: 40% off if you register now. Don’t miss this GREAT DEAL.</li>
              <li>Not permitted: 40% Off If You Register Now. Don’t Miss This Great Deal.</li>
              <li>Acceptable: 40% off if you register now. Don’t miss this great deal.</li>
              <li>
                Capitalization is allowed for common acronyms, abbreviations, or initialisms.
                Capitalization is also allowed for trademarks, brand names, or product names that
                are consistently capitalized on your website or app.
              </li>
              <li>
                Acceptable: 40% off if you register now. Register ASAP to not miss this great deal.
              </li>
              <li>
                Proper nouns (company names, product names, locations, etc.) should be capitalized.
              </li>
              <li>Acronyms (e.g. HTML, NATO, FAQ) should be capitalized.</li>
            </ul>

            <p class="margin-0 desc font-bolder">Punctuation</p>
            <ul class="margin-0 desc">
              <li>Ellipses are not permitted.</li>
              <li>Not permitted: Sign-up for a free trial today…</li>
              <li>Acceptable: Sign-up for a free trial today.</li>
              <li>The Headline and Body Text fields should end with a punctuation mark.</li>
              <li>Not permitted: We are looking for machine learning experts. Learn more today</li>
              <li>Acceptable: We are looking for machine learning experts. Learn more today.</li>
              <li>
                The use of symbols is OK as long as they are being used correctly. Any symbol that
                is used as clickbait (used solely with the intention to grab the reader’s attention)
                is not allowed.
              </li>
            </ul>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="3">3. Display URL policies</p>
          <div fxLayout="column" fxLayoutGap="32px">
            <p class="margin-0 desc font-bolder">Display URLs:</p>
            <ul class="margin-0 desc">
              <li>
                Should reflect the content of your landing page destination and deliver the expected
                experience when clicked. If we determine that your sponsored content does not
                accurately reflect the content of your landing page, they may be rejected.
              </li>
              <li>
                Should consist of lowercase letters. (For example, the words “business” and “UNI”
                should be displayed as business.uni.xyz).
              </li>
              <li>Should not exceed 30 characters.</li>
              <li>Do not use your display URL field as another line for sponsored content.</li>
            </ul>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="4">
            4. Landing pages and destination URL policies
          </p>
          <div fxLayout="column" fxLayoutGap="32px">
            <p class="margin-0 desc">
              Your destination URL must function properly in all browsers and on all platforms. Here
              is what you should be aware of when building your landing pages:
            </p>
            <p class="margin-0 desc font-bolder">Landing Pages:</p>
            <ul class="margin-0 desc">
              <li>Should be in English only.</li>
              <li>
                Should clearly and accurately reflect the product or service being promoted in your
                sponsored content.
              </li>
              <li>
                Should not trigger irrelevant pop-ups or pop-unders when someone arrives upon or
                exits the page.
              </li>
              <li>
                Should not automatically initiate downloads, malware, or spyware upon arrival.
              </li>
              <li>
                Should not link to a file that requires an additional program or application in
                order to view content.
              </li>
              <li>
                Should not have confusing design elements nor include fake clickable elements to
                entice clicks.
              </li>
              <li>
                Should not contain images or words that are included for sensational or shock
                purposes. This includes images and words that are sensational, aggressive,
                offensive, disrespectful, threatening, or excessively violent. We also do not permit
                clickbait content, including anything that is sensationalist, hyperbolic, alarmist,
                or that plays on people’s fears or self-esteem.
              </li>
              <li>Should not mention UNI unless you have obtained permission first.</li>
              <li>Should not include videos that autoplay with sound upon arrival.</li>
            </ul>

            <p class="margin-0 desc font-bolder">Destination URLs:</p>
            <ul class="margin-0 desc">
              <li>
                Should lead to a page owned by your organization, to a web storefront selling your
                product or service, or to an article about your product or service. If we determine
                that your display URLs aren’t accurately reflecting the destination URL you’re
                directing people to, we reserve the right to reject your sponsored content.
              </li>
              <li>
                Should not lead people to a site that is an error page or is under construction.
              </li>
              <li>
                Should not be directed to a page that does not explicitly belong to your company,
                but can be directed to your UNI company account.
              </li>
            </ul>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="5">5. Image policies</p>
          <div fxLayout="column" fxLayoutGap="32px">
            <p class="margin-0 desc">
              You must have the right to use the images and logos when you are putting sponsored by
              a logo. In addition to adhering to our UNI content policies and guidelines, images in
              sponsored content:
            </p>
            <ul>
              <li class="margin-bottom-6 desc">
                Should not lack clarity: images should be relevant to the text in the sponsorship
                copy or landing page, should not have non-English text, and not be too blurry or
                unclear too easily.
              </li>
              <li class="margin-bottom-6 desc">
                Should not be misleading: images should not include elements designed to trick a
                person into clicking, and should not incorporate images with non-existent
                functionality, such as play buttons, notification jewels, or checkboxes that are
                part of the image itself and are not actually functional.
              </li>
              <li class="margin-bottom-6 desc">
                Should not include objectionable or excessive text: images should not include memes,
                profanity, or more than 20% text.
              </li>
              <li class="margin-bottom-6 desc">
                Should not be sexual in nature: images should not depict nudity in any form, sexual
                acts in any form, or sexual innuendo.
              </li>
              <li class="margin-bottom-6 desc">
                Should not include violence: images should not depict violent acts or the physical
                results of violent acts, people harming themselves or others, graphic, gory content,
                nor Images with excessive blood.
              </li>
              <li class="margin-bottom-6 desc">
                Should not include references to alcohol or drugs: images should not depict alcohol
                or drugs in any form, nor nicotine/tobacco products or the use of nicotine/tobacco
                products.
              </li>
              <li class="margin-bottom-6 desc">
                Should not be sensational or controversial: images should not insult or disparage an
                individual person or groups of people, and should not involve sensational images
                that are meant to draw your attention by shocking you.
              </li>
              <li class="margin-bottom-6 desc">
                Should not include “before-and-after” images: images should not show unexpected or
                unlikely results in regard to weight loss, and images must not imply or attempt to
                generate negative self-perception in order to promote diet, weight loss, or other
                health-related products.
              </li>
              <li class="margin-bottom-6 desc">
                Should not include animation: images should not include animations or GIFs.
              </li>
            </ul>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="6">6. Misrepresentation/ Trust</p>
          <div fxLayout="column" fxLayoutGap="32px">
            <p class="margin-0 desc">
              All information presented in your sponsored content must be relevant to the product or
              services you are offering and must not misrepresent what you are trying to promote.
              This applies to all components of your sponsored content as well as your landing
              pages.
            </p>
            <p class="margin-0 desc">In general, we do not permit:</p>
            <ul>
              <li class="margin-0 desc">
                Sponsorships that do not accurately and truthfully represent your company, your
                products, or your services.
              </li>
              <li class="margin-0 desc">
                Promotions prompt users to initiate a purchase, download, or other commitment
                without first providing all relevant information and obtaining the user’s explicit
                consent.
              </li>
            </ul>
            <p class="margin-0 desc">Your sponsored content is likely to be rejected if they:</p>
            <ul>
              <li class="margin-0 desc">
                Promise a promotional offer, then make it difficult to locate on your landing page.
              </li>
              <li class="margin-0 desc">
                Make misleading or exaggerated claims about your products and services.
              </li>
              <li class="margin-0 desc">
                Make false statements about your identity or qualifications.
              </li>
              <li class="margin-0 desc">
                Use false claims or claims that entice the user with an improbable result (even if
                this result is possible) as the likely outcome that a user can expect.
              </li>
              <li class="margin-0 desc">
                Falsely imply affiliation with, or endorsement by, another individual, organization,
                product, or service.
              </li>
              <li class="margin-0 desc">Mislead or trick the user into interacting with them.</li>
              <li class="margin-0 desc">
                Make competitive claims (usually contents that contain superlatives such as “best,”
                “#1,” “top”) without third-party verification on your landing page. Without
                third-party verification, your sponsored content may be considered misleading.
              </li>
              <li class="margin-0 desc">
                Hide or deliberately conceal or misstate information about your business, product,
                or services. This includes not disclosing your billing practices and terms of
                payment.
              </li>
            </ul>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="7">7. Prohibited content</p>
          <div fxLayout="column" fxLayoutGap="32px">
            <p class="margin-0 desc">
              Prohibited content is not allowed on UNI at all and any sponsored content that
              consists of prohibited content will be rejected and removed from UNI.
            </p>
            <p class="margin-0 desc">
              There are 3 principles that guide our decisions on the type of content we prohibit.
              They are:
            </p>
            <ol type="number">
              <li>Content that enables dishonest behavior</li>
              <li>Content that we deem inappropriate for our users and community</li>
              <li>Content that could endanger other people</li>
            </ol>

            <div fxLayout="column" fxLayoutGap="20px">
              <p class="margin-0 subtitle font-bold" id="7.1">
                7.1 Content that enable dishonest or illegal behavior
              </p>
              <p class="margin-0 desc">
                We want to provide an honest and trustworthy experience for our users and so do not
                allow any sponsored content that enables dishonest or illegal behavior on UNI.
              </p>
              <ul>
                <li>Badware and hacking</li>
                <li>
                  Do not promote content such as spyware, malware, or any other type of software
                  that results in a misleading or deceptive user experience.
                </li>
                <li>Copyrighted content and trademark violations</li>
                <li>
                  Content (including landing pages) must not contain any content that infringes upon
                  or violates the rights of any third party. This includes copyright, trademark,
                  privacy, publicity, or other personal or proprietary rights. Sponsors are
                  responsible for proper trademark and copyright use in their sponsored content as
                  well as landing pages and UNI reserves the right to remove sponsored content in
                  response to trademark and copyright owner complaints.
                </li>
                <li>False documentation</li>
                <li>
                  Do not promote or sell false documentation such as fake IDs, passports, or other
                  official documents.
                </li>
                <li>Imitation or counterfeit goods</li>
                <li>
                  Do not promote the sale of counterfeit goods. Counterfeit goods mimic the brand
                  features of the actual product in the attempt to pass themselves off as genuine.
                </li>
                <li>Unsupported businesses</li>
                <li>
                  Do not promote products, services, or business models that we consider
                  unacceptable. This could be related to payday loans, paycheck advances, or other
                  short-term loans.
                </li>
                <li>
                  Do not promote business models that do not clearly explain their billing policy or
                  value proposition, as we consider this deceptive and misleading.
                </li>
                <li>Payday loans</li>
                <li>Do not promote payday loan services.</li>
                <li>Buying social media followers/app installs</li>
                <li>
                  Do not promote services where people can purchase social media followers/likes or
                  app installs/reviews.
                </li>
                <li>Academic fraud</li>
                <li>Do not promote services that enable academic fraud.</li>
                <li>Live animals</li>
                <li>
                  Do not promote the sale of live animals or products from endangered or at-risk
                  species.
                </li>
              </ul>
            </div>

            <div fxLayout="column" fxLayoutGap="20px">
              <p class="margin-0 subtitle font-bold" id="7.2">
                7.2 Content that we deem inappropriate for our users and community
              </p>
              <ul>
                <li>Adult products or services</li>
                <li>
                  Do not promote adult-oriented services or products that include any form of
                  nudity, depictions of people in explicit or suggestive positions, or activities
                  that are overly suggestive or sexually provocative.
                </li>
                <li>Alcohol</li>
                <li>Do not promote or reference alcohol in your sponsored content.</li>
                <li>Weight loss products and services</li>
                <li>
                  Do not promote weight loss products or services that make unrealistic claims
                  encouraging unhealthy weight loss goals.
                </li>
                <li>Do not promote weight loss pills, weight loss supplements, or fat burners.</li>
                <li>Gambling and games</li>
                <li>
                  Do not promote online gambling, lotteries, casinos, sports bookings, fantasy
                  sports, bingo, or poker.
                </li>
                <li>Crowdfunding platforms</li>
                <li>Do not promote campaigns that require crowdsourced funding.</li>
                <li>Astrology, faith healing, and psychic readings</li>
                <li>Deceptive content</li>
                <li>
                  Do not promote misleading news or content that deceives users into believing false
                  information.
                </li>
              </ul>
            </div>

            <div fxLayout="column" fxLayoutGap="20px">
              <p class="margin-0 subtitle font-bold" id="7.3">
                7.3 Sponsored content that could endanger other people
              </p>
              <ul>
                <li>Unsafe supplements, illegal, or recreational drugs</li>
                <li>
                  Do not promote online pharmacies, the sale of prescription and recreational drugs,
                  and supplements that are known to be questionable or unsafe.
                </li>
                <li>Tobacco</li>
                <li>
                  Do not promote tobacco products, which include cigarettes, e-cigarettes, chewing
                  tobacco, or other paraphernalia like pipes, pipe cleaners, or rolling papers.
                </li>
                <li>Violence</li>
                <li>
                  Do not promote acts that we deem shocking, sensationalist, disrespectful, or
                  excessively violent. This includes any content that expresses hatred, harassment,
                  racism, sexism, or religious or political intolerance.
                </li>
                <li>Weapons, ammunition, and explosives</li>
                <li>
                  Do not promote firearms, ammunition, firearm accessories, or other types of
                  weapons. We also do not allow sponsored content that provides instructions on how
                  to handle firearms that may lead to harming or killing people.
                </li>
                <li>High-risk practices</li>
                <li>
                  Do not promote extremely high-risk practices that could result in substantial harm
                  to a person.
                </li>
                <li>Do not promote unrealistic cures.</li>
              </ul>

              <p class="margin-0 font-bolder">Other types of content we prohibit:</p>
              <ul>
                <li>
                  We do not allow sponsors to include their contact information (such as phone
                  numbers or e-mail addresses) in sponsored content.
                </li>
                <li>
                  Information on how sponsors can be contacted should be provided on the landing
                  page, not in the sponsored content.
                </li>
                <li>We do not allow affiliate links or affiliate programs.</li>
                <li>
                  It is OK to link to your own product/service page. However, it is not OK to
                  promote affiliate links.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="8">8. Restricted content</p>
          <p class="margin-0 desc">
            The following content is allowed on UNI on a limited basis. Please contact us if you
            intend to promote a product/service that falls under the following categories.
          </p>
          <ul>
            <li class="margin-0 desc">Contests and sweepstakes</li>
            <li class="margin-0 desc">
              Your content cannot mislead users, so avoid content that may be perceived as enabling
              dishonest behavior. We encourage sponsored content that is held to the same standard
              as the content we value on UNI. Please make sure your content is spam-free and adheres
              to local laws and regulations.
            </li>
            <li class="margin-0 desc">Healthcare and medicine</li>
            <li class="margin-0 desc">
              We restrict the promotion of healthcare-related content. This includes the following:
            </li>
            <li class="margin-0 desc">Over-the-counter medication</li>
            <li class="margin-0 desc">
              Prescription medication and information about the prescription medication
            </li>
            <li class="margin-0 desc">Online and offline pharmacies</li>
            <li class="margin-0 desc">Medical services and procedures</li>
            <li class="margin-0 desc">Clinical trial recruitment</li>
            <li class="margin-0 desc">Cryptocurrency</li>
            <li class="margin-0 desc">We allow the promotion of:</li>
            <li class="margin-0 desc">Exchanges</li>
            <li class="margin-0 desc">Wallets</li>
            <li class="margin-0 desc">Financial Services</li>
            <li class="margin-0 desc">
              ICOs must disclose any financial risk involved in investing on the landing page, a
              separate Terms of Service page, or a white paper. The disclosure must state that the
              value of the tokens being offered is not guaranteed and could depreciate in full at
              any time.
            </li>
            <li class="margin-0 desc">
              All other products and services must receive explicit approval from UNI.
            </li>
            <li class="margin-0 desc">Foreign exchange services</li>
            <li class="margin-0 desc">We allow the promotion of:</li>
            <li class="margin-0 desc">Subscription services</li>
            <li class="margin-0 desc">
              Sponsored content that promotes products or services that include automatic renewal,
              negative options, free-to-pay conversion billing products, or mobile marketing is
              subject to our subscription services requirements:
            </li>
            <li class="margin-0 desc">
              Subscription terms must be made clear to people in the sponsored content, or you must
              use the “Subscribe Now” Call-to-Action.
            </li>
            <li class="margin-0 desc">The landing page must:</li>
            <li class="margin-0 desc">
              Describe and display your terms and conditions, which include important information
              such as how to cancel the service.
            </li>
            <li class="margin-0 desc">Display or include a link to your privacy policy.</li>
            <li class="margin-0 desc">
              Require that people check a box in order to accept the terms and services. The box
              should not be checked by default.
            </li>
            <li class="margin-0 desc">
              Clearly represent the price of your promoted service, including any details of
              recurring charges.
            </li>
            <li class="margin-0 desc">
              If your sponsored content is for a free trial, it must clearly disclose information
              such as any recurring charge beyond the free trial, when the charge will take place,
              and how much it will be.
            </li>
            <li class="margin-0 desc">Political content</li>
            <li class="margin-0 desc">
              We allow content that promotes specific candidates, parties, or political action
              committees if they have been verified by a reseller partnered with UNI.
            </li>
            <li class="margin-0 desc">
              We allow content that promotes specific views on political issues with the purpose of
              influencing an election outcome if they have been verified by a reseller partnered
              with UNI.
            </li>
            <li class="margin-0 desc">
              We do not allow negative campaigning content that attacks a political opponent, which
              is in line with
              <a href="https://uni.xyz/policy/community/acceptable-use" class="no-decor font-bolder"
                >UNI’s Acceptable Use Policy</a
              >.
            </li>
            <li class="margin-0 desc">
              Sponsored content should not use hyperbolic language and play on people’s fears.
            </li>
            <li class="margin-0 desc">
              We allow negative campaigning contents that are fact-based and use neutral language.
            </li>
            <li class="margin-0 desc">
              Sponsored contents must be in compliance with any election “silence periods.”
            </li>
            <li class="margin-0 desc">
              Sponsored content must be in compliance with local laws and regulations.
            </li>
            <li class="margin-0 desc">Dating services</li>
            <li class="margin-0 desc">Sponsored content should not have any sexual emphasis.</li>
            <li class="margin-0 desc">
              Sponsored content should not indicate that people could meet or connect with
              fictitious or specific individuals.
            </li>
            <li class="margin-0 desc">
              Sponsored content should not suggest that a person has or might be searched for or
              interacted with in any way.
            </li>
            <li class="margin-0 desc">
              Sponsored content should not directly assume certain user attributes (race, religion,
              age).
            </li>
          </ul>
        </div>

        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="9">
            9. Choosing the right topics for your campaign
          </p>
          <div fxLayout="column" fxLayoutGap="32px">
            <p class="margin-0 desc">
              Choosing relevant topics for your campaign will ensure that your Sponsored contents
              reach the right people. Conversely, selecting irrelevant or inappropriate topics may
              result in an ineffective campaign and may degrade the user experience on UNI.
            </p>
            <p class="margin-0 desc">
              Here are tips for selecting topics that will result in a more effective campaign:
            </p>
            <div>
              <p class="margin-0 desc font-bolder">Keywords:</p>
              <ul>
                <li class="margin-0 desc">
                  Choose topics that align most with keywords that people typically use when
                  searching for information about your products and services.
                </li>
                <li class="margin-0 desc">
                  Identify keywords within your sponsored content copy that might be relevant and
                  aligned with topics that already exist on UNI.
                </li>
                <li class="margin-0 desc">
                  Look for keywords that you’ve used on other advertising platforms that have been
                  effective and could translate well into preexisting topics on UNI.
                </li>
              </ul>
            </div>
            <div>
              <p class="margin-0 desc font-bolder">Specific topics:</p>
              <ul>
                <li class="margin-0 desc">
                  Selecting relevant and specific topics will ensure your Sponsored content appear
                  on question pages with topics that apply to your products and services. However,
                  keep in mind that if your topics are too specific, you might not be able to reach
                  as many people as you’d like. Applying topics that are too broad may result in
                  lower-quality leads. Your best strategy is to strike the right balance between
                  specific and broad topics.
                </li>
                <li class="margin-0 desc">Your company/product or competitor topics:</li>
                <li class="margin-0 desc">
                  Find out if your company or product has its own topic on UNI and add that as one
                  of your target topics.
                </li>
                <li class="margin-0 desc">
                  See if your competitors and their products have their own topics on UNI that you
                  can use as targeting.
                </li>
                <li class="margin-0 desc">
                  If your campaign is found to have irrelevant or inappropriate topics applied, we
                  will take steps to resolve it, including removing irrelevant topics from your
                  campaign or pausing your campaign, and stopping Sponsored content delivery until
                  the issue is resolved.
                </li>
              </ul>
            </div>
            <p class="margin-0 desc">Questions about our sponsorship policies?</p>
            <p class="margin-0 desc">
              If you have any questions about these policies, please
              <a href="https://uni.xyz/contact" class="no-decor font-bolder">contact us</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
