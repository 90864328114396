import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentLiabilityComponent } from './payment-liability/payment-liability.component';
import { SponsorshipPoliciesComponent } from './sponsorship-policies/sponsorship-policies.component';
import { SponsorshipTermsComponent } from './sponsorship-terms/sponsorship-terms.component';

const routes: Routes = [
  {
    path: 'payment-liability',
    component: PaymentLiabilityComponent
  },
  {
    path: 'sponsorship',
    component: SponsorshipPoliciesComponent
  },
  {
    path: 'sponsorship-terms',
    component: SponsorshipTermsComponent
  },
  {
    path: '**',
    redirectTo: '/policy'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BusinessRoutingModule {}
