<div class="main-container margin-auto" fxLayoutAlign="center stretch">
  <ng-container *ngIf="smallView; else largerView">
    <div class="sidenav-container">
      <div class="sidenav">
        <mat-expansion-panel class="expansion-panel mat-elevation-z0" [(expanded)]="expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="title margin-0 font-bold">Contents</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let section of sections">
            <div class="margin-top-2">
              <ng-container *ngIf="section?.subsections; else defaultView">
                <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      class="margin-0 section sparro-grey-800"
                      (click)="toTop(section.id)">
                      {{ section.text }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let subsection of section.subsections">
                    <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                      {{ subsection.text }}
                    </p>
                  </ng-container>
                </mat-expansion-panel>
              </ng-container>
              <ng-template #defaultView>
                <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                  {{ section.text }}
                </p>
              </ng-template>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </div>
    </div>
  </ng-container>

  <ng-template #largerView>
    <div class="sidenav-container relative">
      <div class="sidenav">
        <p class="title margin-0 font-bold section-border">Contents</p>
        <ng-container *ngFor="let section of sections">
          <div class="section-border margin-top-2">
            <ng-container *ngIf="section?.subsections; else defaultView">
              <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title
                    class="margin-0 section sparro-grey-800"
                    (click)="toTop(section.id)">
                    {{ section.text }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let subsection of section.subsections">
                  <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                    {{ subsection.text }}
                  </p>
                </ng-container>
              </mat-expansion-panel>
            </ng-container>
            <ng-template #defaultView>
              <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                {{ section.text }}
              </p>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <div fxLayout="column" class="margin-9" fxLayoutGap="24px">
    <div class="padding-bottom-3 breadcrumb-section">
      <p class="margin-0 desc">
        <a href="https://uni.xyz/policy" class="no-decor-hover">Platform Policies</a> / Business /
        <span class="font-bold">Sponsorship terms</span>
      </p>
    </div>
    <div class="detail-section margin-top-9">
      <div fxLayout="column" fxLayoutGap="80px">
        <div fxLayout="column" fxLayoutGap="40px">
          <h1 class="margin-0">Sponsorship terms of service</h1>
          <p class="margin-0 desc">Effective from August 20, 2022</p>
        </div>
        <div fxLayout="column" fxLayoutGap="32px">
          <p class="margin-0 desc">
            The following terms (“Sponsorship Terms of Service”) apply to your use of UNI platform
            to promote your brand by associating it with an ongoing competition or projects
            published under the competition.
          </p>
          <p class="margin-0 desc">
            These Terms were written in English. To the extent any translated version of these
            Sponsoring Terms conflicts with the English version, the English version controls.
          </p>
          <p class="margin-0 desc">
            The Sponsorship packages are explained on UNI platform and follow UNI’s
            <a
              href="https://uni.xyz/policy/general-terms/terms-of-service"
              class="no-decor font-bolder"
              >Terms of Service</a
            >
            (“Platform Terms”), and the Platform Terms continue to apply to your use of the
            Sponsorship Program and any other use of the UNI Platform.
          </p>
        </div>
        <div fxLayout="column" fxLayoutGap="48px" id="1">
          <p class="margin-0 title font-bold">1. Sponsoring your brand on UNI</p>
          <ul>
            <li class="margin-bottom-6 desc">
              UNI allows you to sponsor your brand name by associating it with an ongoing or
              upcoming competition on UNI. Your sponsored content must comply with all laws and
              regulations, not be deceptive, not violate the intellectual property or personal
              rights of another party, and must comply with all other UNI Sponsoring Policies
              (“Sponsoring Policies”).
            </li>
            <li class="margin-bottom-6 desc">
              We have the right, but not the responsibility, to review your organization’s
              authenticity or sponsored content for compliance with the Sponsoring Policies, but you
              are responsible for ensuring compliance with the Sponsoring Policies at all times. We
              may reject or suspend the brands that we determine do not comply with Sponsoring
              Policies, or we may terminate your account for repeated violations, so you should
              review the Sponsoring Policies carefully.
            </li>
            <li class="margin-bottom-6 desc">
              Project Lifetime for sponsoring your brand is considered for a minimum of 5 years.
            </li>
            <li class="margin-bottom-6 desc">
              Sponsorship for a lifetime is not valid in the case of insolvency of the company.
            </li>
            <li class="margin-bottom-6 desc">
              Once the 5-year period is over, it will depend on the creator of the project/organizer
              of the competition if they would like to continue with the sponsorship.
            </li>
            <li class="margin-bottom-6 desc">
              Sponsorship can only be canceled if both the parties, the organizer of the competition
              and the owner of the project agree to cancel the sponsorship together.
            </li>
            <li class="margin-bottom-6 desc">
              Once you sponsor a competition, we will start distributing your brand logo and
              associated information at the designated places as chosen under the sponsorship
              packages.
            </li>
            <li class="margin-bottom-6 desc">
              Our reach displayed in the package is estimated and may vary significantly depending
              on the competition/project you choose to sponsor. We are unable to guarantee the
              activity that your sponsored competition will receive such as the number of clicks,
              impressions, or acquisitions your brand placement will receive.
            </li>
            <li class="margin-bottom-6 desc">
              We may optimize the placement, size, and positioning of your sponsored content/brand
              given the targeted competition/project or similar parameters you choose.
            </li>
            <li class="margin-bottom-6 desc">
              We are unable to guarantee the activity that your sponsored content will receive, such
              as the number of clicks, impressions, or acquisitions your brand placement will
              receive.
            </li>
            <li class="margin-bottom-6 desc">
              We will do our best to detect and filter certain clicks, impressions, or related
              activity, but are not responsible for clicks, impressions, or related fraud,
              technological issues, or other potentially invalid activity that may affect the
              cost/reach of sponsoring a competition on UNI.
            </li>
            <li class="margin-bottom-6 desc">
              You can cancel your sponsorship order at any time by reaching us. In case of
              cancellation, we will refund you the remaining amount from your sponsorship on a
              pro-rata basis for the competition duration.
            </li>
            <li class="margin-bottom-6 desc">
              Your sponsored competition will stop running when the competition period is over.
              However, the projects associated with your sponsored brands will remain associated
              till the project/organizer together decides to remove the content from UNI Platform or
              for a minimum of 5 years.
            </li>
            <li class="margin-bottom-6 desc">
              We reserve the right to reject or remove your brand for any reason. You agree that we
              may correct typographical, grammatical, spacing, or formatting errors, with or without
              your express consent. We may terminate your account and your right to sponsor
              competitions on the UNI Platform, on a going-forward basis, at any time in our sole
              discretion.
            </li>
            <li class="margin-bottom-6 desc">
              If you place sponsored content/brand on someone else’s behalf, you must obtain
              permission to place those sponsored content. In addition, you confirm that you have
              the legal authority to bind the sponsors to these Sponsoring Terms and the Platform
              Terms. Each sponsor or client you manage must be managed through separate accounts.
              You must not change the sponsoring organization or client associated with an
              established account; instead, you should set up a new account. If the sponsoring
              organization you represent violates these Sponsoring Terms and the Platform Terms, we
              may hold you responsible for that violation.
            </li>
            <li class="margin-bottom-6 desc">
              Your sponsored content and related content and information may be used for marketing
              or promotional purposes.
            </li>
            <li class="margin-bottom-6 desc">
              Sometimes, insights about UNI users may be generated based on how and whether they
              respond to the sponsored organizations on the UNI Platform. We own any data generated
              from UNI users’ interaction with your brand displayed on the UNI Platform, such as
              whether a particular user clicks on a particular organization on your behalf
              (“Click/View Data”), but you may use any Click/View Data that we provide to you for
              your own internal purposes, such as to analyze the performance of your campaigns. You
              may not directly collect and use any information about how users interact with UNI
              content, except for your own internal purpose related to your use of the Sponsorship
              Manager. You will not combine any information obtained in connection with your use of
              the Sponsorship Manager with personally identifiable information for any purpose.
            </li>
            <li class="margin-bottom-6 desc">
              You will indemnify and hold us harmless for any claim, cost, or expenses (including
              reasonable attorneys’ fees and investigative costs) that arises from an allegation by
              any third party that you have committed any act (or omission) that is a violation of
              these Sponsoring Terms, including the Sponsoring Policies.
            </li>
            <li class="margin-bottom-6 desc">
              You may not issue any press release or make public statements about your relationship
              with UNI or the Sponsorship Manager without our prior written permission.
            </li>
          </ul>
        </div>
        <div fxLayout="column" fxLayoutGap="48px" id="2">
          <p class="margin-0 title font-bold">2. Orders and payments</p>
          <ul>
            <li class="margin-bottom-6 desc">
              The sponsorship of the competition is prepaid only, and the order has to be placed for
              sponsoring a competition either before the launch of the competition or during the
              competition period. UNI will not reduce the sponsorship value for the competition on a
              pro-rata basis if the competition is ongoing.
            </li>
            <li class="margin-bottom-6 desc">
              The amounts charged to you may be subject to and include applicable taxes and fees,
              including without limitation withholding taxes. You are responsible for remitting any
              taxes that apply to your Orders. You agree to indemnify and hold us harmless from and
              against any claim arising out of your failure to do so.
            </li>
            <li class="margin-bottom-6 desc">
              You are responsible for maintaining the security of your Sponsoring account, and you
              will be charged for any orders placed on or through your Sponsoring account, including
              without limitation orders placed without sufficient internal authorization, or through
              mistake or error.
            </li>
            <li class="margin-bottom-6 desc">
              You represent and warrant that you have the legal right to use any payment method used
              by you in connection with any Order.
            </li>
            <li class="margin-bottom-6 desc">
              UNI works with third-party service providers to perform payment processing, including
              card processing and currency exchange (“Payment Agent”). Any payment processing will
              be subject to the terms and privacy policy of the Payment Agent, in addition to these
              Sponsoring Terms. Our current Payment Agent is PayPal, Instamojo and Razorpay and your
              payments are processed by our Payment Agent in accordance with our Payment Agent’s
              terms of service and privacy policy. UNI may share transaction information (namely,
              the amount of payment due) with our Payment Agent, but not credit card information,
              which you provide to the Payment Agent directly, and not to UNI. UNI may replace its
              Payment Agent at any time.
            </li>
            <li class="margin-bottom-6 desc">
              If UNI has agreed to take payment from you via check/wire transfer, then you may make
              payments in that manner, provided that you hereby agree to the additional terms
              specified here.
            </li>
            <li class="margin-bottom-6 desc">
              If your payment method fails or your account is past due, we may take steps to collect
              past due amounts using other collection methods. You agree to pay all expenses
              associated with such collection, including reasonable attorney's fees. Interest will
              accrue on any past due amounts at the rate of the lesser of 1% per month or the lawful
              maximum.
            </li>
            <li class="margin-bottom-6 desc">
              If you have the option to promote sponsored content/brand with a prepaid account,
              amounts prepaid are non-refundable except where required by law. UNI does not offer
              banking services; accordingly, prepaid amounts do not earn interest, are not deposit
              obligations, and are not insured by any Financial Services Compensation Scheme, or any
              other entity or insurance scheme, whether governmental or private.
            </li>
            <li class="margin-bottom-6 desc">
              We reserve the right to contact your funding instrument issuer, law enforcement, or
              relevant third parties (including other users) and share details of any payments you
              are associated with if we believe doing so may prevent financial loss or a violation
              of the law.
            </li>
            <li class="margin-bottom-6 desc">
              Without limitation of the above rules, if you believe that a problematic transaction
              has taken place under your account, you agree to notify us immediately, so that we may
              take action to prevent financial loss.
            </li>
            <li class="margin-bottom-6 desc">
              There may be countries that restrict or prohibit your ability to make payments through
              the UNI Platform. Nothing in these Sponsoring Terms should be read to override or
              circumvent any such foreign laws.
            </li>
          </ul>
        </div>
        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="3">3. Display URL policies</p>
          <p class="margin-0 desc">
            UDuring the course of your decision to register for or use the Sponsorship Manager, you
            may receive information relating to the UNI Platform, designated in writing by us as
            Confidential (“Confidential Information”). In such event, you agree that you will not
            disclose such Confidential Information to any third party without prior written
            permission from us.
          </p>
        </div>
        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="4">
            4. Landing pages and destination URL policies
          </p>
          <div fxLayout="column" fxLayoutGap="32px">
            <div fxLayout="column" fxLayoutGap="20px">
              <p class="margin-0 subtitle font-bold" id="4.1">4.1 Notice to you</p>
              <p class="margin-0 desc">
                Using the Sponsorship Manager or any tool for sponsoring competitions on UNI
                constitutes agreement that we may communicate with you electronically any important
                information regarding your Orders or your account. We may also provide notices to
                you by posting them on our website, or by sending them to an email address or street
                address that you previously provided to us. Website and email notices shall be
                considered received by you within 24 hours of the time posted or sent; notices by
                postal mail shall be considered received within three (3) business days of the time
                sent.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="20px">
              <p class="margin-0 subtitle font-bold" id="4.2">4.2 Notice to us</p>
              <p class="margin-0 desc">
                Unless stated otherwise, you must send notices to us relating to these Sponsoring
                Terms to: <br />
                <a href="mailto:sponsor@uni.xyz" class="no-decor font-bolder">sponsor@uni.xyz</a>
              </p>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="48px">
          <p class="margin-0 title font-bold" id="5">5. Miscellaneous</p>
          <div fxLayout="column" fxLayoutGap="32px">
            <p class="margin-0 desc">
              These Sponsoring Terms constitute the entire agreement about your use of sponsoring
              services and supersede any other agreement, oral or written, about this subject
              (including any prior signed confidentiality agreement to the extent it conflicts with
              these Sponsoring Terms), except for any prior agreement by you to the Platform Terms.
              These Sponsoring Terms specifically include the Platform Terms and Sponsoring Policies
              and Pixel Terms, which expressly are incorporated herein. You may not modify the
              Sponsoring Terms without written agreement from us. In the event of any conflict
              between these Sponsoring Terms and the Platform Terms, these Sponsoring Terms shall
              govern with respect to your use of the Sponsorship Manager to the extent of the
              conflict. We may ask you to review and accept supplemental terms that apply to your
              use of a specific feature or functionality made available through the Sponsorship
              Manager. To the extent that those supplemental terms conflict with these Sponsoring
              Terms, the supplemental terms will govern with respect to your use of the specific
              feature or functionality to the extent of the conflict.
            </p>
            <p class="margin-0 desc">
              We may modify these Sponsoring Terms, including the Platform Terms, Sponsoring
              Policies, or Pixel Terms, at any time. You should review the Sponsoring Policies
              periodically to remain informed about our current policies. If we amend material terms
              to this Agreement, such amendment will be effective after we send you reasonable
              notice of the amended agreement. Such notice could include, for example, via email,
              posted notice on the UNI Platform or Sponsored contents Manager Platform, or another
              manner. Your continued use of the Sponsorship Manager after reasonable notice to you
              of any material changes to the Sponsoring Terms, Platform Terms, Sponsoring Policies,
              or after non-material changes, constitutes acceptance of those changes. If you do not
              agree to the amendments or to any of the terms in this Agreement, your only remedy is
              to cease use of the Sponsorship Manager or sponsoring competitions on UNI.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
