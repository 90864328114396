import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

@Component({
  selector: 'app-sponsorship-policies',
  templateUrl: './sponsorship-policies.component.html',
  styleUrls: ['./sponsorship-policies.component.scss']
})
export class SponsorshipPoliciesComponent implements OnInit {
  smallView: boolean = false;
  expanded: boolean = false;

  sections = [
    {
      text: `Business name policies`,
      id: `1`
    },
    {
      text: `Sponsorship copy policies`,
      id: `2`
    },
    {
      text: `Display URL policies`,
      id: `3`
    },
    {
      text: `Landing pages and destination URL policies`,
      id: `4`
    },
    {
      text: `Image policies`,
      id: `5`
    },
    {
      text: `Misrepresentation/Trust`,
      id: `6`
    },
    {
      text: `Prohibited content`,
      id: `7`,
      subsections: [
        {
          text: `Content that enable dishonest or illegal behavior`,
          id: `7.1`
        },
        {
          text: `Content that we deem inappropriate for our users and community`,
          id: `7.2`
        },
        {
          text: `Sponsored content that could endanger other people`,
          id: `7.3`
        }
      ]
    },
    {
      text: `Restricted content`,
      id: `8`
    },
    {
      text: `Choosing the right topics for your campaign`,
      id: `9`
    }
  ];

  constructor(public breakpointObserver: BreakpointObserver, public metaService: MetaService) {}

  ngOnInit(): void {
    this.initializeBreakpoints();
    this.updateMetaInfo();
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
    });
  }

  toTop(id: string) {
    var element = document.getElementById(id);
    var headerOffset = this.smallView ? 250 : 150;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    this.expanded = false;
  }

  updateMetaInfo(): void {
    const title = 'Sponsorship policies- UNI';
    const description = `These Sponsoring Policies govern all sponsored content and brands' 
      logos that run on the UNI platform. These sponsoring policies expressly are incorporated 
      into the Sponsoring Terms. All capitalized terms in these Sponsoring Policies will have the 
      same meaning as in the Sponsoring Terms.`;
    const cover = `https://uni-common-assets.s3.us-east-1.amazonaws.com/logo/uni/uni_logo_393x393.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
